<script setup lang="ts">
import {
	DialogContent,
	DialogOverlay,
	DialogPortal,
	DialogRoot,
	DialogTrigger,
	Separator,
	Toggle,
} from "radix-vue";
import { ref } from "vue";
import { buttonVariant } from "@/components/ui/button";
import InstagramIcon from "@/icons/instagram.svg?component";
import LinkedInIcon from "@/icons/linkedIn.svg?component";
import XIcon from "@/icons/x.svg?component";
import FacebookIcon from "@/icons/facebook.svg?component";
import BurgerToggle from "./BurgerToggle.vue";
import { useQuery } from "@tanstack/vue-query";
import { CurrentUserQuery } from "@/features/users/client/users.client";
import i18n from "i18next";

const { data: me } = useQuery(CurrentUserQuery);

const isOpen = ref(false);

const menuItems: {
	label: string;
	href: string;
}[] = [
	{
		label: i18n.t("layout.forOrganizers"),
		href: "#",
	},
	{
		label: i18n.t("layout.forPartners"),
		href: "#",
	},
	{
		label: i18n.t("layout.userAgreement"),
		href: "#",
	},
];

const legalMenuItems = [
	{
		label: i18n.t("layout.privacyPolicy"),
		href: "#",
	},
	{
		label: i18n.t("layout.terms"),
		href: "#",
	},
];

const socialItems = [
	{
		label: i18n.t("social.facebook"),
		icon: FacebookIcon,
		href: "#",
	},
	{
		label: i18n.t("social.instagram"),
		icon: InstagramIcon,
		href: "#",
	},
	{
		label: i18n.t("social.linkedin"),
		icon: LinkedInIcon,
		href: "#",
	},
	{
		label: i18n.t("social.twitter"),
		icon: XIcon,
		href: "#",
	},
];
</script>

<template>
	<DialogRoot v-model:open="isOpen">
		<DialogTrigger as-child>
			<Toggle
				:pressed="isOpen"
				class="pointer-events-auto rounded p-1.5 text-stone-200 outline-none ring-white/25 hover:text-stone-50 focus-visible:ring-2 md:hidden"
			>
				<BurgerToggle v-model="isOpen" class="text-zinc-600" />
			</Toggle>
		</DialogTrigger>
		<DialogPortal>
			<DialogOverlay />
			<DialogContent
				:aria-describedby="undefined"
				@interact-outside.prevent
				@focus-outside.prevent
				@pointer-down-outside.prevent
				class="fixed inset-y-0 left-0 top-[65px] z-10 w-screen gap-4 bg-white px-6 backdrop-blur ease-out data-[state=closed]:duration-300 data-[state=open]:duration-300 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:slide-out-to-bottom-2 data-[state=open]:slide-in-from-bottom-2 md:px-10"
			>
				<div class="flex flex-col py-3">
					<template v-if="!me">
						<div class="flex justify-center gap-2 pb-2 pt-4">
							<a
								:class="
									buttonVariant({ variant: 'secondary', class: 'flex-1' })
								"
								href="/auth/login"
							>
								{{ i18n.t("layout.logIn") }}
							</a>
							<a
								:class="buttonVariant({ variant: 'primary', class: 'flex-1' })"
								href="/auth/register"
							>
								{{ i18n.t("layout.register") }}
							</a>
						</div>
						<Separator class="my-2 h-px w-full bg-zinc-300" />
					</template>

					<a
						href="/conferences"
						class="py-3 font-medium text-zinc-600 hover:text-zinc-500"
					>
						{{ i18n.t("layout.conferences") }}
					</a>
					<a
						href="/categories"
						class="py-3 font-medium text-zinc-600 hover:text-zinc-500"
					>
						{{ i18n.t("layout.categories") }}
					</a>
					<Separator class="my-1 h-px w-full rounded-full bg-zinc-200" />
					<a
						v-for="item of menuItems"
						:href="item.href"
						class="py-3 font-medium text-zinc-600 hover:text-zinc-500"
					>
						{{ item.label }}
					</a>
					<Separator class="my-2 h-px w-full rounded-full bg-zinc-200" />
					<a
						v-for="item of legalMenuItems"
						:href="item.href"
						class="py-3 font-medium text-zinc-600 hover:text-zinc-500"
					>
						{{ item.label }}
					</a>
					<Separator class="my-2 h-px w-full rounded-full bg-zinc-200" />
					<ul class="flex gap-1 py-3">
						<li v-for="item of socialItems">
							<a :href="item.href" class="group flex p-1">
								<component
									:is="item.icon"
									class="h-6 text-zinc-400 group-hover:text-zinc-500"
								/>
							</a>
						</li>
					</ul>
				</div>
			</DialogContent>
		</DialogPortal>
	</DialogRoot>
</template>
