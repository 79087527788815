<script setup lang="ts">
import GlobeIcon from "@/icons/globe.svg?component";
import {
	DropdownMenu,
	DropdownMenuCheckboxItem,
	DropdownMenuContent,
	DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import Cookies from "universal-cookie";
import { LOCALE_NAMES, LOCALES } from "@/i18n/shared";
import { getCurrentLocale } from "@/i18n/client";
import { defaultLocation } from "@vueuse/core";

const currentLocale = getCurrentLocale();
const cookies = new Cookies();
</script>

<template>
	<DropdownMenu :modal="false">
		<DropdownMenuTrigger
			class="mx-1 flex items-center justify-start gap-x-1.5 whitespace-nowrap border-none bg-transparent px-2.5 py-2 transition-colors xs:justify-end"
		>
			<span class="text-sm font-medium tracking-wide">
				{{ currentLocale.toUpperCase() }}
			</span>
			<GlobeIcon
				aria-hidden="true"
				class="size-4 shrink-0 stroke-[1.25] text-zinc-400"
			/>
		</DropdownMenuTrigger>
		<DropdownMenuContent align="end">
			<DropdownMenuCheckboxItem
				v-for="locale in LOCALES"
				:key="locale"
				:value="locale"
				class="cursor-pointer"
				:checked="locale === currentLocale"
				@click="
					() => {
						cookies.set('locale', locale, {
							path: '/',
							expires: new Date(Date.now() + 365 * 24 * 60 * 60 * 1000),
						});
						defaultLocation?.reload();
					}
				"
			>
				{{ LOCALE_NAMES[locale] }}
			</DropdownMenuCheckboxItem>
		</DropdownMenuContent>
	</DropdownMenu>
</template>
