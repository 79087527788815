<script setup lang="ts">
import { buttonVariant } from "@/components/ui/button";
import MobileMenu from "./MobileMenu.vue";
import SearchDialog from "./SearchDialog.vue";
import type { User } from "@/features/users/server";
import Logo from "@/icons/logo.svg?component";
import { useQuery, useQueryClient } from "@tanstack/vue-query";
import { CurrentUserQuery } from "@/features/users/client/users.client";
import { getCDNImageURL, PLACEHOLDER_AVATAR } from "@/utils/cdn";
import LanguageDropdown from "./LanguageDropdown.vue";
import { getCurrentLocale, loadI18n } from "@/i18n/client";

const props = defineProps<{
	user: User | null;
}>();

const queryClient = useQueryClient();
queryClient.setQueryData(CurrentUserQuery.queryKey, props.user);
const { data: user } = useQuery(CurrentUserQuery);

const i18n = await loadI18n(getCurrentLocale());
</script>

<template>
	<header
		class="pointer-events-auto fixed top-0 z-20 w-full border-b border-zinc-200 bg-white bg-opacity-90 px-5 py-3 backdrop-blur-[20px] backdrop-saturate-150 md:px-10"
	>
		<div class="container flex items-center justify-between gap-5 md:gap-6">
			<div class="flex flex-1 items-center justify-start gap-5">
				<a
					href="/"
					class="flex items-center gap-1.5 whitespace-nowrap text-lg font-bold tracking-tight text-zinc-900"
				>
					<Logo class="size-6 shrink-0" />
					{{ i18n.t("website") }}
				</a>
				<div class="hidden md:flex">
					<a
						href="/conferences"
						class="flex h-9 items-center rounded-md px-3 text-sm font-semibold transition-colors ease-out hover:bg-zinc-400/15"
					>
						{{ i18n.t("layout.conferences") }}
					</a>
					<a
						href="/categories"
						class="flex h-9 items-center rounded-md px-3 text-sm font-semibold transition-colors ease-out hover:bg-zinc-400/15"
					>
						{{ i18n.t("layout.categories") }}
					</a>
				</div>
			</div>
			<SearchDialog class="min-w-32 flex-1" />
			<div class="flex flex-1 items-center justify-end gap-2 md:gap-3">
				<MobileMenu />
				<LanguageDropdown />
				<a
					v-if="user"
					href="/cabinet"
					class="flex shrink-0 items-center gap-2.5"
				>
					<span class="hidden text-sm font-semibold md:block">
						{{ user.name }}
					</span>
					<img
						alt="User avatar"
						:src="
							user.avatarKey
								? getCDNImageURL(user.avatarKey)
								: PLACEHOLDER_AVATAR
						"
						class="size-10 shrink-0 rounded-full"
					/>
				</a>
				<div v-else class="hidden md:flex md:gap-1">
					<a
						:class="
							buttonVariant({
								variant: 'secondary',
								size: 'sm',
								class: 'shrink-0',
							})
						"
						href="/auth/login"
					>
						{{ i18n.t("layout.logIn") }}
					</a>
					<a
						:class="
							buttonVariant({
								variant: 'primary',
								size: 'sm',
								class: 'shrink-0',
							})
						"
						href="/auth/register"
					>
						{{ i18n.t("layout.register") }}
					</a>
				</div>
			</div>
		</div>
	</header>
</template>
