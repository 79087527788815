import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 48 48"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M26.98 6a16.5 16.5 0 0 1 8.32 9.71 16.3 16.3 0 0 1-1.18 12.7 7.9 7.9 0 0 0-3.28-10.32 8 8 0 0 0-3.81-1.04H2L6.05 6z",
      fill: "#636BEA"
    }, null, -1),
    _createElementVNode("path", {
      d: "M46 25c0 4.22-1.42 8.32-4.02 11.65a19.3 19.3 0 0 1-22.73 5.78 19 19 0 0 1-9.18-8.28 16.5 16.5 0 0 0 9.44 2.93 16.6 16.6 0 0 0 14.61-8.67A16.34 16.34 0 0 0 27 6h-.03c5.06.03 9.9 2.04 13.46 5.6A19 19 0 0 1 46 25.01",
      fill: "#636BEA"
    }, null, -1),
    _createElementVNode("path", {
      d: "M33.33 29.7a16.5 16.5 0 0 1-13.82 7.38q-1.4 0-2.77-.23c-2.4-.4-4.68-1.32-6.67-2.7-1-1.8-1.7-3.76-2.06-5.8l2.1-5.7h11.84l-2.2 6.01a8 8 0 0 0 6.5 4.27 8 8 0 0 0 7.08-3.24",
      fill: "#636BEA"
    }, null, -1)
  ])))
}
export default { render: render }