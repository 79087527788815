<script setup lang="ts">
import SearchIcon from "@/icons/search.svg?component";
import { queryOptions, useQuery } from "@tanstack/vue-query";
import {
	Combobox,
	ComboboxAnchor,
	ComboboxContent,
	ComboboxEmpty,
	ComboboxInput,
} from "@/components/ui/combobox";
import { computed, ref } from "vue";
import { useThrottleFn } from "@vueuse/core";
import LoaderIcon from "@/icons/loader.svg?component";
import { PublicConferencesQuery } from "@/features/conferences/client/service";
import SearchDialogItem from "./SearchDialogItem.vue";
import i18n from "i18next";

const isOpen = ref(false);
const search = ref("");

const setSearchWithThrottle = useThrottleFn(
	(newValue: string) => {
		search.value = newValue;
	},
	300,
	true,
);

const { data: conferences, isLoading } = useQuery(
	computed(() => {
		return queryOptions({
			...PublicConferencesQuery({
				limit: 6,
				search: search.value,
				// min_ends_at: new Date(),
			}),
			enabled: search.value !== "",
			placeholderData: (data, query) => {
				if (query?.isActive()) {
					return data;
				}
			},
		});
	}),
);
</script>

<template>
	<Combobox
		:filter-function="(data) => data"
		:search-term="search"
		@update:search-term="setSearchWithThrottle"
		v-model:open="isOpen"
	>
		<ComboboxAnchor class="border-transparent bg-zinc-700/5">
			<SearchIcon class="-ml-1 mr-0.5 size-5 stroke-[1.5] text-zinc-400" />
			<ComboboxInput
				:placeholder="i18n.t('search.placeholder')"
				@focus="search ? (isOpen = true) : undefined"
			/>
		</ComboboxAnchor>
		<ComboboxContent
			class="fixed -left-1/2 -right-1/2 mx-auto w-[90vw] px-1 py-0 shadow-md shadow-zinc-500/15 md:absolute md:w-[150%]"
		>
			<ComboboxEmpty>
				<template v-if="search === ''">
					{{ i18n.t("search.idle") }}
				</template>
				<div
					v-else-if="isLoading"
					class="flex w-full items-center justify-center"
				>
					<LoaderIcon
						class="mr-1.5 size-4 animate-spin stroke-[1.5] text-zinc-400"
					/>
					{{ i18n.t("search.loading") }}
				</div>
				<template v-else> {{ i18n.t("search.notFound") }} </template>
			</ComboboxEmpty>
			<SearchDialogItem
				v-if="conferences?.items"
				v-for="conference of conferences.items"
				:conference="conference"
			/>
		</ComboboxContent>
	</Combobox>
</template>
