<script setup lang="ts">
import { useConferencePlatform } from "@/features/conferences/client/platform";
import type { PublicConference } from "@/features/conferences/server/shared";
import { getCDNImageURL } from "@/utils/cdn";
import { formatDateToMonthDay } from "@/dayjs";
import {
	AvatarRoot,
	AvatarImage,
	AvatarFallback,
	ComboboxItem,
} from "radix-vue";
import { AvatarGroup } from "@/components/ui/overflow-indicator";
import MapPinIcon from "@/icons/map-pin.svg?component";

const props = defineProps<{
	conference: PublicConference;
}>();

const platform = useConferencePlatform(() => props.conference);
</script>

<template>
	<ComboboxItem
		:value="conference"
		:key="conference.id"
		as="a"
		:href="`/conferences/${conference.id}`"
		class="group flex min-w-0 gap-3 rounded p-3 data-[highlighted]:bg-zinc-100"
	>
		<div class="flex min-w-0 flex-1 flex-col">
			<h3
				class="mb-1 line-clamp-2 text-sm font-bold underline-offset-2 group-hover:underline"
			>
				{{ conference.name }}
			</h3>
			<span class="text-xs font-medium text-gray-500">
				<template v-if="conference.type === 'OFFLINE'">
					<MapPinIcon
						class="mr-0.5 inline-block size-4 align-sub text-zinc-400"
					/>
					{{ conference.country }}, {{ conference.city }}
				</template>
				<template v-else-if="platform">
					<component
						:is="platform.icon"
						class="mx-1 inline-block size-4 align-sub"
					/>
					{{ platform.name }}
				</template>

				&bull; {{ formatDateToMonthDay(conference.startsAt) }}
			</span>
		</div>
		<AvatarGroup
			v-if="conference.speakers.length > 0"
			class="flex"
			:items="conference.speakers"
			:max="3"
			v-slot="{ visibleItems, surplusCount }"
		>
			<AvatarRoot
				v-for="speaker of visibleItems"
				class="-mr-2 size-6 select-none overflow-hidden rounded-full border-2 border-white last:mr-0"
			>
				<AvatarImage
					class="size-full"
					height="24"
					width="24"
					:src="
						speaker.avatarKey
							? getCDNImageURL(speaker.avatarKey)
							: '/images/placeholder_image.webp'
					"
				/>
				<AvatarFallback
					class="mx-auto size-full text-center text-sm font-medium text-zinc-500"
				>
					{{ speaker.name[0] }}
				</AvatarFallback>
			</AvatarRoot>
			<div
				v-if="surplusCount > 0"
				class="flex size-7 items-center justify-center rounded-full border-2 border-white bg-indigo-100 text-center text-xs font-semibold leading-6 text-indigo-500"
			>
				+{{ surplusCount }}
			</div>
		</AvatarGroup>
	</ComboboxItem>
</template>
