import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 24 24"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "currentColor",
      d: "M17.234 2.25H6.744a4.51 4.51 0 0 0-3.198 1.33A4.47 4.47 0 0 0 2.25 6.778v10.444a4.455 4.455 0 0 0 1.296 3.198a4.494 4.494 0 0 0 3.197 1.33h4.763v-6.966h-1.82a.428.428 0 0 1-.427-.425v-2.236a.435.435 0 0 1 .438-.436h1.809v-2.18a3.54 3.54 0 0 1 .996-2.826a3.573 3.573 0 0 1 2.811-1.065h1.854a.428.428 0 0 1 .427.436v1.89a.424.424 0 0 1-.427.424h-1.123c-1.236 0-1.472.582-1.472 1.431v1.879h2.696a.428.428 0 0 1 .427.48l-.27 2.237a.424.424 0 0 1-.427.38h-2.415v6.966h2.674a4.509 4.509 0 0 0 3.197-1.33a4.471 4.471 0 0 0 1.296-3.199V6.778a4.453 4.453 0 0 0-1.304-3.206a4.494 4.494 0 0 0-3.212-1.322"
    }, null, -1)
  ])))
}
export default { render: render }